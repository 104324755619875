<template>
  <div>
    <main class="site-content container">
      <div>
        <ul role="tablist" class="zones-nav nav">
          <li
            class="nav-item"
            v-for="(labelItem, i) in labelInSearch"
            :key="i"
            @click="activeTab = labelItem.key"
          >
            <span
              :id="`${labelItem.key}-tab`"
              data-toggle="tab"
              :href="`#${labelItem.key}`"
              role="tab"
              :aria-controls="labelItem.key"
              :aria-selected="true"
              :class="[
                'nav-link search-cursor-tab text-uppercase',
                { active: labelItem.key === activeTab },
              ]"
              >{{ labelItem.name }}</span
            >
          </li>
        </ul>
        <div class="tab-content">
          <div
            v-for="(labelItem, i) in labelInSearch"
            :key="i"
            :id="labelItem.key"
            role="tabpanel"
            :aria-labelledby="`${labelItem.key}-tab`"
            :class="[
              'tab-pane fade show',
              { active: labelItem.key === activeTab },
            ]"
          >
            <div class="search-main-container search-main-container__nieuws">
              <autocomplete
                :get-result-value="getResultValuePost"
                :search="search"
                :placeholder="`Zoek &amp; vind ${labelItem.name} in ${siteName} ...`"
                @submit="handleSubmit"
              >
                <template #result="{ result, props }">
                  <li v-bind="props" class="autocomplete-result">
                    <div
                      class="
                        autocomplete-result-item autocomplete-result-item-big
                      "
                    >
                      <img
                        :src="
                          renderImageFromBlogPost(result.content.$t) ||
                          defaultImage
                        "
                        @error="detectImageError"
                      />
                      <h4>{{ result.title.$t }}</h4>
                    </div>
                    <!-- <div class="wiki-snippet" v-html="result.snippet" /> -->
                  </li>
                </template>
              </autocomplete>
              <button
                id="ioj1o1"
                class="
                  button
                  search-btn
                  text-uppercase
                  btn btn-primary
                  text-white
                  border-0
                "
                @click="handleSubmit('')"
              >
                Zoeken
              </button>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div
          v-if="
            (type === 'search' &&
              searchLoaded &&
              finalSearchResult.length === 0) ||
            !finalSearchResult
          "
          class="zones-placeholder-container"
        >
          <h3>Geen resultaat gevonden.</h3>
        </div>
        <div v-if="type === 'search-post'" class="zones-placeholder-container">
          <div v-if="finalSearchResult.length === 0">
            <h3>Geen resultaat gevonden.</h3>
          </div>

          <div
            v-if="finalSearchResult.length > 0"
            class="zones-placeholder-container"
          >
            <div>
              <h2>Resultaten: {{ finalSearchResult.length }}</h2>
              <table class="table table-hover table-striped">
                <thead>
                  <th><strong>Name</strong></th>
                </thead>
                <tr v-for="resultItem in finalSearchResult">
                  <td class="zones-result-container">
                    <div class="zones-search-result__image">
                      <a :href="readPost(resultItem)">
                        <img
                          :src="
                            renderImageFromBlogPost(resultItem.content.$t) ||
                            defaultImage
                          "
                      /></a>
                    </div>
                    <div class="zones-search-result__description">
                      <h4 class="mb-3">
                        <a :href="readPost(resultItem)">{{
                          resultItem.title.$t
                        }}</a>
                      </h4>
                      <p
                        class="mb-2 zones-search-result__description-p"
                        v-html="prune(stripHtml(resultItem.content.$t))"
                      ></p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import ReadMore from "@/components/readmore.vue";
import { getImageFromBlogPost } from "@/helpers/index.js";
export default {
  components: {
    ReadMore,
  },
  data() {
    return {
      type: '',
      currentTarget: "",
      blogUrl: "",
      siteName: "",
      activeTab: "",
      loaded: false,
      searchLoaded: false,
      labelInSearch: [],
      searchResult: {},
      searchPlaceholder: {},
      finalSearchResult: [],
      news: [],
      sectors: {},
      companyLists: [],
      pageOfItems: [],
      defaultImage: "",
    };
  },
  created() {
    let labels = this.siteAttributes.labels.split(",");
    labels.forEach((item) => {
      this.labelInSearch.push({
        key: item.replace(/ /g, "-"),
        name: item,
      });
      this.searchResult[item] = [];
      this.searchPlaceholder[item] = [];
    });
    this.blogUrl = this.siteAttributes.url;
    this.siteName = this.siteAttributes.name;
    this.activeTab = this.siteAttributes.activeTab;
    this.defaultImage = this.siteAttributes.defaultImage;
  },
  methods: {
    removeNbsp: (str) => {
      return str.replace(/&nbsp;/gi, "");
    },
    stripHtml: (str, elemToUse = "") => {
      if (!str) {
        return "";
      }
      return utils.removeNbsp(str.replace(/<(?:.|\n)*?>/gm, elemToUse));
    },
    prune: (str, length = 250) => {
      return str.length > length ? str.substring(0, length - 3) + "..." : str;
    },
    jsonp(url) {
      return new Promise((resolve, reject) => {
        var callbackName =
          "jsonp_callback_" + Math.round(100000 * Math.random());
        window[callbackName] = function (data) {
          delete window[callbackName];
          document.body.removeChild(script);
          //callback(data);
          resolve(data);
        };

        var script = document.createElement("script");
        script.src =
          url +
          (url.indexOf("?") >= 0 ? "&" : "?") +
          "callback=" +
          callbackName;
        document.body.appendChild(script);
      });
    },
    async getData(label) {
      let startIndex = 1;
      let selected = [];
      let isCompleted = false;

      const getBlogPost = async () => {
        let url = `${this.blogUrl}/feeds/posts/default?start-index=${startIndex}&max-results=150&alt=json`;
        if (label) {
          url = `${this.blogUrl}/feeds/posts/default/-/${label}?start-index=${startIndex}&max-results=150&alt=json`;
        }
        // let result = await fetch(url);
        let result = await this.jsonp(url);
        if (result && result.feed.entry) {
          selected = [...selected, ...result.feed.entry];
          if (result.feed.entry.length >= 150) {
            startIndex += result.feed.entry.length;
            getBlogPost();
          } else {
            isCompleted = true;
            return selected;
          }
        } else {
          isCompleted = true;
          return selected;
        }
      };

      let dataResult = await getBlogPost();
      return dataResult;
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },

    getResultValuePost(result) {
      return result.title.$t;
    },
    getResultValue(result) {
      return result.name;
    },
    renderSearchResult(type, payload) {
      this.type = "search";
      if (type === "post" && payload) {
        this.finalSearchResult = payload;
      } else if (!payload) {
        this.finalSearchResult = [];
      }
      this.searchLoaded = true;
    },
    renderImageFromBlogPost(content) {
      return getImageFromBlogPost(content);
    },
    handlePost(result, objTarget) {
      this.currentTarget = objTarget;
      if (result) {
        let link = result.link.filter((l) => l.rel == "alternate")[0].href;
        window.location.href = link;
      } else {
        this.renderSearchResult("post", this.searchResult[objTarget]);
        this.type = "search-post";
      }
    },
    handleSubmit(result) {
      this.handlePost(result, this.activeTab);
    },
    readPost(result) {
      let link = result.link.filter((l) => l.rel == "alternate")[0].href;
      return link;
    },
    async search(input) {
      if (this.searchPlaceholder[this.activeTab].length == 0) {
        this.searchPlaceholder[this.activeTab] = await this.apiGetNews(
          this.activeTab
        );
      }
      this.searchResult[this.activeTab] =
        this.searchPlaceholder[this.activeTab].filter((post) => {
          return post.title.$t.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }) || [];
      return this.searchResult[this.activeTab];
    },
    async apiGetNews(label) {
      try {
        return new Promise(async (resolve, reject) => {
          let result = await this.getData(label);
          resolve(result);
        });
      } catch (err) {
        console.log(err);
      }
    },
    shuffleArray(array) {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    },
  },
};
</script>

<style>
/* :root {
  --theme: #15303d;
  --theme-hover: #9e1f31;
} */

/* .site-content {
  font-family: Circular, Helvetica, Arial, sans-serif;
} */

.site-content img {
  max-width: 100%;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.search-cursor-tab {
  cursor: pointer;
  background-color: var(--theme);
  color: #dedbdb;
  border-radius: 0;
  font-size: 18px;
  padding: 12px 20px;
  font-family: "Lato", san-serif;
  margin-right: 10px;
  font-family: Lato, sans-serif;
}

.search-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-input-field,
.autocomplete-input {
  height: 60px;
  margin: 0 20px 0 0;
  font-size: 22px !important;
  border-radius: 0 !important;
  border: 1px solid #dedede;
  background-color: #fcfcfc !important;
}

.active.search-cursor-tab {
  background-color: var(--theme-hover) !important;
  border-color: var(--theme-hover);
  color: white !important;
}

.search-main-container div {
  width: 100%;
  margin-right: 20px;
  z-index: 10;
}

.autocomplete-result-item {
  display: flex;
}

.autocomplete-result-item img {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  object-fit: contain;
  border: 1px solid #ddd;
  padding: 5px;
}

.autocomplete-result-item-big img {
  width: 60px;
  min-width: 60px;
  height: 60px;
  object-fit: cover;
}

.autocomplete-result-item-big h4 {
  display: flex;
  max-width: 80%;
}

.zones-placeholder {
  margin-bottom: 20px;
}

.zones-placeholder-container {
  border: 1px solid #eee;
  padding: 20px;
  background: white;
  margin-top: 20px;
}

.btn,
.utd-btn {
  border-radius: 0;
}

.zones-result-container {
  display: flex;
  padding-top: 20px !important;
  border-bottom: 1px solid #eee;
}

.zones-search-result__image {
  margin-right: 30px;
  /* padding: 5px; */
}

.zones-search-result__image img {
  width: 100px;
  height: 70px;
  /* margin-right: 20px; */
  object-fit: contain;
  border: 1px solid #ddd;
  padding: 5px;
}

.autocomplete-result {
  cursor: pointer;
  padding: 10px;
  background: white;
}

.zones-search-result__description {
  width: 100%;
}

.zones-search-result__description p {
  color: #777;
}

.zones-search-result__description span {
  /* color: #aaa; */
  margin-bottom: 10px;
}

.text-link {
  cursor: pointer;
  font-family: "Lato", san-serif;
}

@media screen and (max-width: 768px) {
  .search-btn {
    display: none;
  }

  .search-main-container {
    flex-direction: column;
  }

  .zones-nav .nav-item {
    margin-bottom: 10px;
  }

  .zones-search-result__description {
    margin-top: 20px;
  }

  .search-main-container div {
    margin: 0;
  }

  .search-cursor-tab {
    font-size: 14px;
    padding: 10px 15px;
  }

  .zone-title {
    text-align: center;
  }

  .search-input-field,
  .autocomplete-input {
    font-size: 16px !important;
  }

  .autocomplete-result-item-big img {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
}
@media only screen and (max-width: 768px) {
  .zones-result-container {
    display: unset;
  }

  .zones-staff-pick a,
  .zones-staff-pick div {
    width: 100%;
  }

  .search-btn {
    margin-top: 20px;
  }
}


.badge-info-custom {
  border: 1px solid #e7e7e7;
  padding: 5px;
  color: #aaa;
}

.pagination .page-item.active .page-link {
  color: white;
  background: var(--theme-hover);
  border: none;
}
.pagination .page-link {
  color: var(--theme);
}

.autocomplete-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #899297;
  opacity: 1; /* Firefox */
}

.autocomplete-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #899297;
}

.autocomplete-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #899297;
}
</style>