var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('main',{staticClass:"site-content container"},[_c('div',[_c('ul',{staticClass:"zones-nav nav",attrs:{"role":"tablist"}},_vm._l((_vm.labelInSearch),function(labelItem,i){return _c('li',{key:i,staticClass:"nav-item",on:{"click":function($event){_vm.activeTab = labelItem.key}}},[_c('span',{class:[
              'nav-link search-cursor-tab text-uppercase',
              { active: labelItem.key === _vm.activeTab } ],attrs:{"id":((labelItem.key) + "-tab"),"data-toggle":"tab","href":("#" + (labelItem.key)),"role":"tab","aria-controls":labelItem.key,"aria-selected":true}},[_vm._v(_vm._s(labelItem.name))])])}),0),_c('div',{staticClass:"tab-content"},_vm._l((_vm.labelInSearch),function(labelItem,i){return _c('div',{key:i,class:[
            'tab-pane fade show',
            { active: labelItem.key === _vm.activeTab } ],attrs:{"id":labelItem.key,"role":"tabpanel","aria-labelledby":((labelItem.key) + "-tab")}},[_c('div',{staticClass:"search-main-container search-main-container__nieuws"},[_c('autocomplete',{attrs:{"get-result-value":_vm.getResultValuePost,"search":_vm.search,"placeholder":("Zoek & vind " + (labelItem.name) + " in " + _vm.siteName + " ...")},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"result",fn:function(ref){
          var result = ref.result;
          var props = ref.props;
return [_c('li',_vm._b({staticClass:"autocomplete-result"},'li',props,false),[_c('div',{staticClass:"\n                      autocomplete-result-item autocomplete-result-item-big\n                    "},[_c('img',{attrs:{"src":_vm.renderImageFromBlogPost(result.content.$t) ||
                        _vm.defaultImage},on:{"error":_vm.detectImageError}}),_c('h4',[_vm._v(_vm._s(result.title.$t))])])])]}}],null,true)}),_c('button',{staticClass:"\n                button\n                search-btn\n                text-uppercase\n                btn btn-primary\n                text-white\n                border-0\n              ",attrs:{"id":"ioj1o1"},on:{"click":function($event){return _vm.handleSubmit('')}}},[_vm._v(" Zoeken ")])],1)])}),0)]),_c('section',[(
          (_vm.type === 'search' &&
            _vm.searchLoaded &&
            _vm.finalSearchResult.length === 0) ||
          !_vm.finalSearchResult
        )?_c('div',{staticClass:"zones-placeholder-container"},[_c('h3',[_vm._v("Geen resultaat gevonden.")])]):_vm._e(),(_vm.type === 'search-post')?_c('div',{staticClass:"zones-placeholder-container"},[(_vm.finalSearchResult.length === 0)?_c('div',[_c('h3',[_vm._v("Geen resultaat gevonden.")])]):_vm._e(),(_vm.finalSearchResult.length > 0)?_c('div',{staticClass:"zones-placeholder-container"},[_c('div',[_c('h2',[_vm._v("Resultaten: "+_vm._s(_vm.finalSearchResult.length))]),_c('table',{staticClass:"table table-hover table-striped"},[_vm._m(0),_vm._l((_vm.finalSearchResult),function(resultItem){return _c('tr',[_c('td',{staticClass:"zones-result-container"},[_c('div',{staticClass:"zones-search-result__image"},[_c('a',{attrs:{"href":_vm.readPost(resultItem)}},[_c('img',{attrs:{"src":_vm.renderImageFromBlogPost(resultItem.content.$t) ||
                          _vm.defaultImage}})])]),_c('div',{staticClass:"zones-search-result__description"},[_c('h4',{staticClass:"mb-3"},[_c('a',{attrs:{"href":_vm.readPost(resultItem)}},[_vm._v(_vm._s(resultItem.title.$t))])]),_c('p',{staticClass:"mb-2 zones-search-result__description-p",domProps:{"innerHTML":_vm._s(_vm.prune(_vm.stripHtml(resultItem.content.$t)))}})])])])})],2)])]):_vm._e()]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('th',[_c('strong',[_vm._v("Name")])])])}]

export { render, staticRenderFns }