import Vue from 'vue'
import App from './App.vue'
import Autocomplete from '@trevoreyre/autocomplete-vue'
// import VueContentPlaceholders from 'vue-content-placeholders'
import '@trevoreyre/autocomplete-vue/dist/style.css'


let app = document.getElementById('app')
let props = {
  url: app.getAttribute('data-url'),
  name: app.getAttribute('data-name'),
  activeTab: app.getAttribute('data-active-tab'),
  labels: app.getAttribute('data-labels'),
  dafaultImage: app.getAttribute('data-dafault-image'),
}

Vue.prototype.siteAttributes = props
Vue.config.productionTip = false

const detectImageError = {
  install(Vue, options) {
    Vue.prototype.detectImageError = (e) => {
      e.target.src = window.defaultImage || '';
    }
  },
}
Vue.use(detectImageError)
Vue.use(Autocomplete)
// Vue.use(VueContentPlaceholders)

new Vue({
  render: h => h(App),
}).$mount('#app')
